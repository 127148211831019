/* Main navigation */
.navbar {
	padding: 0;

	&-toggler {
		&:focus {
			outline: 0;
		}
	}
}

.navbar-dark .navbar-nav .nav-link {
	color: #fff;
}

.navbar-light .navbar-nav .nav-link {
	color: #333;
}

.navbar-nav .nav-link {
	text-transform: uppercase;
	font-weight: bold;
	padding: 20px 15px 18px !important;
	transition: .2s ease;
	font-size: 14px;

	@include desktop {
		padding: 10px !important;
	}
}

/* Dropdown */
.dropdown {
	&:hover {
		.dropdown-menu {
			visibility: visible;
			opacity: 1;
			transform: scaleX(1);
		}
	}

	&-item {
		display: block;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
		text-decoration: none;
		padding: 15px;
		color: #333;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		transition: .2s ease;

		&:hover {
			background: #fff;
		}
	}

	&-menu {
		padding: 0 15px;
		border: 0;
		border-radius: 0px;
		width: 100%;
		box-shadow: none;
		background: none;
		min-width: 180px;
		top: 100%;
		left: -10px;
		border-radius: 0;
		display: block;
		visibility: hidden;
		transition: .3s ease;
		opacity: 0;
		transform: scale(.8);
		background: #fff;

		@include desktop {
			display: none;
			opacity: 1;
			visibility: visible;
			transform: scale(1);
			transform-origin: unset;
			text-align: center;
		}

		&.show {
			visibility: hidden;

			@include desktop {
				visibility: visible;
				display: block;
			}
		}
	}
}

/*-- Header style 1 --*/
.header {

	.navbar {
		@include desktop {
			padding: 16.5px 0;
		}
	}
}

.header-solid {
	background: #191919;
	box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
}

/*-- Logo --*/
.navbar-brand.navbar-bg {
	position: absolute;
	left: 0;
	padding: 3px 15px 10px;
	width: 20%;
	height: 62px;
	line-height: 62px;
	@include desktop {
		width: auto;
	}
}

/* Header Style - 2
================================================== */

.header2 {
	padding: 8px 0;

	@include desktop {
		background: #191919;
		box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
	}

	.navbar {
		padding: 0;
	}
}

.header2 .navbar-brand {
	background: none;
	padding-top: 3px;
}

/* Header Style - 3
================================================== */

.header3 {
	.navbar {
		background: #fff;
		border-radius: 0 0 5px 5px;
		box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);

		&-brand {
			background: transparent;
		}

		&-toggler {
			background: transparent;
		}
	}
}


/* Header Style - 4
================================================== */

.header4 {
	background: #fff;
	box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);

	.navbar {
		&-brand {
			background: transparent;
		}

		&-toggler {
			background: transparent;
		}
	}
}