/* Slideshow
================================================== */

/*-- Main slide --*/

.overlay {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url(../images/overaly.png);
}

#main-slide .carousel-item img {
	width: 100%;
	-webkit-backface-visibility: hidden;
}

#main-slide .carousel-item {
	-webkit-backface-visibility: hidden;
}

#main-slide .carousel-item .slider-content {
	z-index: 1;
	opacity: 0;
	transition: opacity 500ms;
}

#main-slide .carousel-item.active .slider-content {
	opacity: 1;
	transition: opacity 100ms;
}

#main-slide .slider-content {
	top: 50%;
	margin-top: -70px;
	left: 0;
	padding: 0;
	text-align: center;
	position: absolute;
	width: 100%;
	color: #fff;
}

#main-slide .carousel-indicators {
	bottom: 70px;
}

.carousel-indicators li {
	width: 30px !important;
	height: 10px !important;
	margin: 1px !important;
	border-radius: 0 !important;
	background: rgba(255, 255, 255, .5);
	border: 1px solid #fff;
}

#main-slide .carousel-indicators li.active,
#main-slide .carousel-indicators li:hover {
	transition: all 300ms ease;
}


#main-slide .carousel-control.left,
#main-slide .carousel-control.right {
	opacity: 0;
	filter: alpha(opacity=0);
	background-image: none;
	background-repeat: no-repeat;
	text-shadow: none;
	transition: all .25s ease;
}

#main-slide:hover .carousel-control.left,
#main-slide:hover .carousel-control.right {
	opacity: 1;
	filter: alpha(opacity=100);
}

#main-slide:hover .carousel-control.left {
	left: 20px;
}

#main-slide:hover .carousel-control.right {
	right: 20px;
}

#main-slide .carousel-control.left span {
	padding: 15px;
}

#main-slide .carousel-control.right span {
	padding: 15px;
}

#main-slide .carousel-control .fa-angle-left,
#main-slide .carousel-control .fa-angle-right {
	position: absolute;
	top: 50%;
	z-index: 5;
	display: inline-block;
}

#main-slide .carousel-control .fa-angle-left {
	left: 0;
}

#main-slide .carousel-control .fa-angle-right {
	right: 0;
}

#main-slide .carousel-control i {
	background: none;
	color: #fff;
	line-height: 56px;
	width: 56px;
	height: 56px;
	border: 1px solid #fff;
	border-radius: 100%;
	font-size: 36px;
	transition: all 500ms ease;
}

#main-slide .carousel-control i:hover {
	border: 1px solid rgba(0, 0, 0, .3);
}

#main-slide .slider-content h2 {
	font-size: 52px;
	text-transform: uppercase;
	color: #fff;
}

#main-slide .slider-content h3 {
	font-size: 28px;
	margin-top: 40px;
	color: #fff;
}

.slider.btn {
	padding: 10px 35px;
	margin-top: 40px;
	font-size: 16px;
	text-transform: uppercase;
	color: #fff;
}

.slider.btn.solid {
	margin-left: 15px;
}

.slider-content-left {
	position: relative;
	margin: 0 0 0 40px;
}

.slider-content-right {
	position: relative;
}

/*-- Animation */

.carousel .carousel-item.active .animated2,
.flex-active-slide .animated2 {
	-webkit-animation: lightSpeedIn 1s ease-in 200ms both;
	animation: lightSpeedIn 1s ease-in 200ms both;
}

.carousel .carousel-item.active .animated3,
.flex-active-slide .animated3 {
	-webkit-animation: bounceInLeft 2s ease-in-out 800ms both;
	animation: bounceInLeft 1.5s ease-in-out 800ms both;
}

.carousel .carousel-item.active .animated4,
.flex-active-slide .animated4 {
	-webkit-animation: flipInX 2s ease-in 800ms both;
	animation: flipInX 2s ease-in 800ms both;
}

.carousel .carousel-item.active .animated5,
.flex-active-slide .animated5 {
	-webkit-animation: bounceInLeft 1s ease-in-out 100ms both;
	animation: bounceInLeft 1s ease-in-out 100ms both;
}

.carousel .carousel-item.active .animated6,
.flex-active-slide .animated6 {
	-webkit-animation: bounceIn 1s ease-in 500ms both;
	animation: bounceIn 1s ease-in 500ms both;
}

.carousel .carousel-item.active .animated7,
.flex-active-slide .animated7 {
	-webkit-animation: bounceIn 1s ease-in 500ms both;
	animation: bounceIn 1s ease-in 500ms both;
}

.carousel .carousel-item.active .animated8,
.flex-active-slide .animated8 {
	-webkit-animation: bounceInDown 1s ease-in 800ms both;
	animation: bounceInDown 1s ease-in 800ms both;
}


/* Flex slider */

.ts-flex-slider {
	position: relative;
}

.ts-flex-slider .flexSlideshow {
	width: 100%;
	overflow: hidden;
}


.overlay2 {
	position: relative;
}

.overlay2 img {
	width: 100%;
}

.overlay2::before {
	position: absolute;
	content: '';
	z-index: 3;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url(../images/overaly.png);
}

.ts-flex-slider .flexslider .slides {
	z-index: 0;
}

.ts-flex-slider .slider-content {
	z-index: 3;
}


.ts-flex-slider .flex-direction-nav a {
	width: 55px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	font-size: 32px;
}

.ts-flex-slider .flex-direction-nav a.flex-next {
	border-radius: 3px;
}

.ts-flex-slider .flex-direction-nav a.flex-prev {
	border-radius: 3px;
}


/* Services
================================================== */

.service {
	padding-top: 40px;
}

.service-heading {
	padding-bottom: 30px;
	text-align: center;
}

.service-content {
	margin-top: 30px;
}

.service-content h3 {
	font-size: 16px;
}

/* Feature box
================================================== */

.feature-icon {
	font-size: 28px;
	margin-top: 5px;
}

.feature-content {
	padding-right: 20px;
	padding-left: 55px;
}

.feature-content h3 {
	margin-top: 0;
	font-size: 18px;
}


/* Feature center */

.feature-center-content .feature-center-icon {
	font-size: 72px;
	margin-bottom: 5px;
	display: inline-block;
}

.feature-center-content h3 {
	font-size: 18px;
}


/* Image Block
================================================== */

.image-block {
	background: #f4f4f4;
}

.img-block-right {
	padding-right: 140px;
}

.img-block-head h2 {
	font-size: 18px;
	line-height: normal;
	margin: 5px 0 0 0;
}

.img-block-head h3 {
	font-size: 40px;
	line-height: 100%;
}

.image-block-content {
	padding: 0 50px;
	margin-bottom: 30px;
}

.image-block-content .feature-icon {
	width: 60px;
	height: 60px;
	line-height: 60px;
	color: #fff;
	border-radius: 100%;
	display: inline-block;
	text-align: center;
}

.image-block-content .feature-content {
	padding-left: 85px;
}

.image-block-content .feature-content h3 {
	margin-bottom: 0;
}




/* About us
================================================== */


/* Landing Tab */

.landing-tab {
	.nav-link {
		position: relative;
		color: #6A6A6A;
		transition: .2s ease;
		font-weight: bold;

		i {
			font-size: 30px;
		}

		&:hover {
			color: inherit !important;

			i {
				transition: inherit;
			}
		}

		&::before {
			position: absolute;
			content: "";
			height: 100%;
			width: 5px;
			right: -3px;
			top: 0;
			opacity: 0;
		}

		&.active {
			background: transparent;
			color: #6A6A6A;

			&::before {
				opacity: 1;
			}
		}
	}
}


/* Featured Tab */

.featured-tab {
	.nav-link {
		position: relative;
		background: #f2f2f2;
		border-radius: 0;
		padding: 10px;
		transition: all 300ms ease;

		&.active {
			&:hover {
				color: #fff !important;
			}
		}

		&::before {
			left: 100%;
			border: transparent solid;
			content: "";
			position: absolute;
			border-width: 25px;
			top: 50%;
			transform: translateY(-50%);
			border-left-color: #f2f2f2;
			transition: inherit;
		}
	}
}

/* Portfolio
================================================== */

.portfolio {
	background: #f5f5f5;
	padding-bottom: 0;
}

#main-container.portfolio {
	background: none;
}

/*-- Isotope Portfolio start --*/

#isotope .isotope-item {
	padding: 0;
}

.isotope-nav {
	margin: -80px auto 80px;
	display: inline-block;
	float: right;
	position: relative;
	z-index: 100;
}

.isotope-nav ul {
	list-style: none;
	margin: 0;
}

.isotope-nav ul li {
	float: left;
	margin-right: 10px;
}

.isotope-nav ul li a {
	color: #323232;
	padding: 5px 15px 8px;
	border: 1px solid #ddd;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.isotope-nav ul a.active,
.isotope-nav ul a:hover {
	color: #fff;
	position: relative;
	border: 1px solid transparent;
}

.isotope-nav ul a.active:after,
.isotope-nav ul a:hover:after {
	position: absolute;
	content: " ";
	width: auto;
	height: auto;
	bottom: -13px;
	margin-left: -8px;
	left: 50%;
	border-width: 7px;
	border-style: solid;
}

#isotope .isotope-item .grid {
	position: relative;
	margin-bottom: -8px;
}

.grid figure {
	position: relative;
	z-index: 1;
	display: inline-block;
	overflow: hidden;
	background: #3085a3;
	text-align: center;
	width: 100%;
	height: 100%;
}

.grid figure figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure.effect-oscar {
	background: #000;
	background: rgba(0, 0, 0, .8);
}

figure.effect-oscar img {
	display: block;
	width: 100%;
	opacity: 1;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure.effect-oscar figcaption {
	padding: 2.5em;
	background-color: rgba(0, 0, 0, 0.1);
	-webkit-transition: background-color 0.35s;
	transition: background-color 0.35s;
}

figure.effect-oscar figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 1px solid rgba(255, 255, 255, .3);
	content: '';
}

figure.effect-oscar h3 {
	font-size: 18px;
	color: #fff;
	opacity: 0;
	margin: 25% 0 10px 0;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}

figure.effect-oscar a {
	color: #fff;
	font-size: 18px;
	margin: 20px 5px 0;
	display: inline-block;
	z-index: 1;
	position: relative;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}


figure.effect-oscar a.link i {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

figure.effect-oscar figcaption::before,
figure.effect-oscar a {
	opacity: 0;
	-webkit-transition: all 0.35s, -webkit-transform 0.35s;
	transition: all 0.35s, transform 0.35s;
	-webkit-transform: scale(0);
	transform: scale(0);
}

figure.effect-oscar:hover h3 {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover h3,
figure.effect-oscar:hover a {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}


figure.effect-oscar:hover figcaption {
	background-color: rgba(0, 0, 0, 0.5);
}

figure.effect-oscar:hover img {
	opacity: 0.4;
}


/* Portfolio box */

.portfolio-box {
	padding-bottom: 80px;
}

.portfolio-box .isotope-nav {
	margin: 0 auto 60px;
	float: none;
}


/* Portfolio details */

.portfolio-static-item {
	padding-bottom: 20px;
}

.portfolio-static-item figure.effect-oscar a {
	margin-top: 70px;
}

.portfolio-static-desc h3 {
	font-size: 16px;
	margin: 5px 0 -5px 0;
	line-height: normal;
}

.portfolio-static-desc a {
	color: #6A6A6A;
}


/* Portfolio details */

.portfolio-desc h3 {
	margin-top: 0;
}

.portfolio-desc .project-btn {
	margin-top: 20px;
	padding: 8px 25px;
}



/* Counter
================================================== */


.facts-wrapper {
	background: #2a2d32;
	color: #fff;
}

.facts {
	position: relative;
	padding: 46px 40px 30px;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.facts:hover {
	background: #000 !important;
}

.facts.one {
	background: rgba(0, 0, 0, .3);
}

.facts.two {
	background: rgba(0, 0, 0, .2);
}

.facts.three {
	background: rgba(0, 0, 0, .15);
}

.facts.four {
	background: rgba(0, 0, 0, .09);
}

.facts i {
	font-size: 36px;
	text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
}

.facts-num span {
	font-size: 42px;
	font-weight: 700;
	margin-top: 20px;
	display: inline-block;
}

.facts h3 {
	font-size: 18px;
	margin-top: 8px;
	color: #fff;
}


/* Counter parallax bg */

.ts_counter_bg .facts {
	padding: 0 15px;
}

.ts_counter_bg .facts:hover {
	background: none !important;
}

.ts_counter_bg .facts-num span {
	margin: 30px 0 20px;
}

.ts_counter.elements .facts {
	padding: 15px;
}

/* Team
================================================== */

.img-hexagon span {
	position: absolute;
	display: block;
	float: left;
	border-left: 120px solid #fff;
	border-right: 120px solid #fff;
}

.img-top {
	top: 0;
	border-top: 0px solid transparent;
	border-bottom: 75px solid transparent;
}

.img-bottom {
	bottom: 0px;
	border-bottom: 0px solid transparent;
	border-top: 75px solid transparent;
}

.img-hexagon {
	width: 240px;
	height: 250px;
	position: relative;
	display: inline-block;
}

.img-hexagon img {
	width: 100%;
	height: 100%;
}


.team-content h3 {
	margin-bottom: 0;
	font-size: 20px;
}

.team-content p {
	color: #959595;
}

.team-social a {
	background: #d5d5d5;
	color: #fff;
	width: 28px;
	height: 28px;
	line-height: 28px;
	border-radius: 100%;
	display: inline-block;
	text-align: center;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.team-social a.fb:hover {
	background: #325c94;
}

.team-social a.twt:hover {
	background: #00abdc;
}

.team-social a.gplus:hover {
	background: #dd4b39;
}

.team-social a.linkdin:hover {
	background: #007bb6;
}

.team-social a.dribble:hover {
	background: #ea4c89;
}


/* Team list */

.team-list .img-hexagon {
	float: left;
	margin-right: 30px;
}


/* Team list square */

.team-list-square .img-square {
	float: left;
	margin-right: 30px;
}


/* Pricing table
================================================== */

.pricing {
	padding-bottom: 80px;
}

.plan {
	border: 1px solid #dedede;
}

.plan-name {
	display: block;
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	padding: 20px 0;
	color: #959595;
}

.plan-price {
	font-size: 48px;
	padding: 30px 0;
	margin-bottom: 30px;
	position: relative;
	background: #f2f2f2;
}

.plan-price:after {
	bottom: -30px;
	border: solid transparent;
	content: " ";
	position: absolute;
	border-width: 15px;
	border-top-color: #f2f2f2;
	left: 50%;
	margin-left: -16px;
}

.plan.featured .plan-name {
	color: #323232;
}

.plan.featured .plan-price {
	color: #fff;
}

.plan-price .currency {
	top: -0.9em;
	font-size: 50%;
	left: -0.01em;
	font-weight: 700;
}

.plan-name small {
	display: block;
	font-size: 12px;
	font-weight: 700;
	line-height: normal;
}

.plan-price sub {
	font-size: 18px;
	font-weight: 700;
}

.plan ul {
	background: #fff;
	padding: 0;
	margin-bottom: 0;
}

.plan ul li {
	border-top: 1px dashed #dedede;
	padding: 12px 29px;
	font-weight: 500;
}

.plan ul li:first-child {
	border-top: 0 !important;
}

.plan a.btn {
	color: #323232;
	background: #fff;
	margin-top: 25px;
	margin-bottom: -20px;
	padding: 8px 30px;
}

.plan a.btn:hover {
	color: #fff;
}


/* Testimonial
================================================== */

.testimonial-thumb img {
	border: 1px solid #8E8E8E;
	padding: 3px;
	width: 80px;
	height: 80px;
	border-radius: 100%;
}

.testimonial-slide .testimonial-content h3.name {
	font-size: 18px;
	color: #fff;
	margin: 10px 0;
}

.testimonial-slide .testimonial-content h3 span {
	display: block;
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	margin-top: -10px;
}

.testimonial-slide .owl-buttons {
	display: none;
}

.testimonial-slide .testimonial-text {
	font-size: 16px;
	line-height: 24px;
	max-width: 800px;
	padding-top: 30px;
	display: inline-block;
	position: relative;
	color: #fff;
}

.testimonial-slide .testimonial-text:before {
	content: '\f10d';
	font-family: FontAwesome;
	padding-right: 10px;
}

.testimonial-slide .testimonial-text:after {
	content: '\f10e';
	font-family: FontAwesome;
	padding-left: 10px;
}

.testimonial-slide.owl-theme .owl-controls .owl-page span {
	background: none;
	border: 1px solid #fff;
	opacity: .7;
}

.testimonial-slide.owl-theme .owl-controls .owl-page.active span {
	border: 1px solid transparent;
}

/* Video block
================================================== */

.featured-video {
	background: #fff;
}

.video-block-head h2 {
	font-size: 16px;
	margin: 0;
	line-height: normal;
}

.video-block-head h3 {
	font-size: 24px;
	line-height: normal;
	margin: 0;
}

.video-block-content {
	padding: 10px 0;
}

.video-block-content h3 {
	margin: 0;
	line-height: normal;
}

.video-block-content .btn.btn-primary {
	color: #fff;
	margin: 5px 0 0 40px;
	padding: 8px 25px;
}

.video-block-content .btn.btn-primary:hover {
	color: #333;
}


/* Recent Post
================================================== */

.post-img-wrapper {
	position: relative;
}

.post-img-overlay {
	position: relative;
	display: inline-block;
}

.post-img-overlay::before {
	position: absolute;
	border-radius: 1px;
	background-color: rgba(0, 0, 0, 0);
	content: '';
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.post-img-overlay:hover::before {
	background-color: rgba(0, 0, 0, .5);
}

.post-img-overlay a {
	position: absolute;
	right: 0;
	color: #fff;
	background: #666666;
	padding: 15px 20px;
	opacity: 0;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.post-img-overlay a.link {
	right: 0;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
}

.post-img-overlay:hover a.link {
	left: 0;
	right: auto;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

}

.post-img-overlay a.view {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}

.post-img-overlay:hover a.view {
	bottom: 0;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.recent-post {
	padding: 0 15px;
}

.recent-post .post-body h3 {
	font-size: 18px;
	margin-bottom: 3px;
	font-weight: 700;
}

.recent-post .post-body h3 a {
	color: #323232;
}

.recent-post .post-body .post-meta a {
	font-weight: 400;
	font-size: 13px;
}

.recent-post .post-body .post-meta span,
.recent-post .post-body .post-meta span i {
	padding-right: 5px;
}

.recent-post .post-excerpt {
	padding-top: 10px;
}

.recent-post .post-excerpt a.read-more {
	padding: 6px 18px;
	border-width: 1px;
	font-size: 12px;
	margin-top: 10px;
}


/* Clients
================================================== */

.clients {
	color: #fff;
	padding-bottom: 80px;
}

.clients-content {
	padding: 40px 0;
}

.clients h2.title {
	border-left: 0;
	color: #fff;
}

.client-carousel .item a {
	margin-right: 20px;
	border: 1px solid #e7e7e7;
	border-radius: 3px;
	display: inline-block;
	padding: 10px;
	-webkit-transition: all 350ms;
	-ms-transition: all 350ms;
	transition: all 350ms;
}


/* Twitter
================================================== */

.ts_twitter {
	padding: 100px 0;
}

.twitter-feed .twitter-icon i {
	font-size: 78px;
	color: #1dcaff;
}

.twitter-feed .tweet a {
	color: #1dcaff;
}

.twitter-feed .tweet a:hover {
	color: #000;
}

.twitter-feed .tweet p {
	font-size: 18px;
	font-style: italic;
	padding-top: 20px;
}

/* Contact
================================================== */

#contact-form {
	margin-top: 25px;
}

#contact-form label {
	font-weight: normal;
}

.map-wrapper {
	position: relative;
}

.map {
	height: 400px;
	z-index: 1;
}

.contact-info-inner h3 {
	margin-bottom: 25px;
	font-size: 24px;
	color: #fff;
}

.contact-info h3 {
	margin-top: 42px;
	font-size: 24px;
}

.contact-info p {
	font-size: 18px;
}

.contact-info i {
	font-size: 18px;
	margin-right: 10px;
}

/* Contact inside Map */
.contact-info-inner {
	position: absolute;
	color: #fff;
	top: 0;
	bottom: 0;
	z-index: 2;
	background: rgba(0, 0, 0, .7);
	padding: 25px 30px;
	max-width: 400px;
	max-height: 400px;
	text-align: left;
}

.contact-info-inner p {
	overflow: hidden;
	margin-bottom: 25px;
}

.contact-info-inner p strong {
	display: block;
}

.contact-info-inner i {
	margin-right: 20px !important;
	font-size: 24px;
	margin-top: 5px;
}


/* Footer
================================================== */

.footer {
	padding: 60px 0 100px;
	background: #2e2e2e url(../images/footer-bg.jpg) no-repeat center 0;
	background-size: cover;
	color: #fff;
	font-size: 14px;
}

.footer-logo {
	margin-bottom: 20px;
}

.footer-map {
	padding-bottom: 60px;
}

.footer2 {
	background: #2e2e2e;
}

.footer .widget-title {
	color: #fff;
}

.footer .social-icon {
	padding: 10px 0 20px;
}


/* Latest Post */

.footer-widget .latest-post-image img {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	border: 4px solid rgba(255, 255, 255, .5);

}

.footer-widget .latest-post-content h4 a {
	color: #fff;
}

.latest-post-items {
	margin-bottom: 10px;
}

.latest-post-image img {
	width: 70px;
	height: 60px;
}

.footer-widget .post-meta,
.footer-widget .post-meta a {
	color: #fff;
}

.latest-post-content h4 {
	font-size: 14px;
	line-height: normal;
}


/* Image Gallery */

.footer-widget .img-gallery {
	margin-top: 5px;
	display: inline-block;
}

.img-gallery a.thumb-holder {
	overflow: hidden;
	position: relative;
}

.img-gallery a.thumb-holder img {
	margin: 0 15px 15px 0;
	display: inline-block;
	width: 95px;
	height: 85px;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.img-gallery a.thumb-holder:hover img {
	opacity: 0.7;
}


.footer2 .img-gallery a.thumb-holder img {
	width: 90px;
	height: 75px;
}


/* Footer about us */

.footer-about-us h4 {
	font-size: 16px;
	margin-bottom: 0;
	line-height: normal;
}

.subscribe {
	margin-top: 20px;
}

.subscribe input.form-control {
	border: 1px solid rgba(255, 255, 255, .1);
}

.subscribe .input-group-addon {
	background: none;
	border-radius: 0;
	border: 0;
	padding: 0;
}

.subscribe button {
	padding: 13px 20px;
	color: #fff;
}

.subscribe button:hover {
	background: #222;
	color: #fff;
}

.subscribe button i {
	font-size: 20px;
}


/* Footer links */

.footer ul li {
	color: #fff;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.footer ul.arrow li {
	width: 50%;
	float: left;
}

ul.arrow li:before {
	content: "\f105";
	font-family: FontAwesome;
	margin-right: 10px;
}

.footer ul li a {
	color: #fff;
}

/* Copyright
================================================== */

#copyright {
	padding: 30px 0;
	background: #191919;
	color: #fff;
}

.copyright-info {
	font-size: 16px;
	border-top: 1px solid rgba(255, 255, 255, .15);
	max-width: 850px;
	margin: 30px auto 0;
	padding-top: 15px;
}

.copyright.angle:before {
	background: #191919;
	height: 50px;
	top: -25px;
	-webkit-transform: skew(0deg, -2deg);
	-moz-transform: skew(0deg, -2deg);
	-ms-transform: skewX(0deg) skewY(-2deg);
	-o-transform: skew(0deg, -2deg);
	transform: skew(0deg, -2deg);
}

.footer-social {
	position: relative;
	z-index: 10;
}

.footer-social .icon-pentagon i {
	font-size: 16px;
}

/* Footer sytle 2 */

.footer2 .social-icon {
	padding-bottom: 15px;
}

.footer2 .copyright-info {
	color: #323232;
	border-top: 1px solid #e7e7e7;
}


#back-to-top {
	right: 40px;
	top: auto;
	z-index: 10;
	-webkit-backface-visibility: hidden;
}

#back-to-top.affix {
	bottom: 60px;
}

#back-to-top .btn.btn-primary {
	border-radius: 100%;
	padding: 0;
	font-size: 18px;
	width: 36px;
	height: 36px;
	background: rgba(0, 0, 0, .3);
	border: 2px solid transparent;
	color: #fff;
	opacity: .5;
}

#back-to-top .btn.btn-primary:hover {
	color: #fff;
	opacity: 1;
}


/* Sub pages
================================================== */

/* Banner area */

#banner-area {
	position: relative;
}

.banner-title-content {
	position: absolute;
	top: 50%;
	width: 100%;
	height: 100%;
}

.banner-title-content h2 {
	color: #fff;
	font-size: 36px;
}

.banner-title-content .breadcrumb {
	background: none;
	color: #fff;
	font-weight: 700;
	font-size: 16px;
}

.banner-title-content .breadcrumb a:hover {
	color: #ccc;
}

/* About Us */

.about-message {
	background: #f4f4f4;
	min-height: 374px;
}

.about-message ul li a {
	color: #6A6A6A;
}



/* Services */

/* Call to Action */

.call-to-action {
	color: #fff;
	padding: 40px 0;
}

.call-to-action.dark {
	background: #2a2d32;
}

.call-to-action h3 {
	color: #fff;
	float: left;
	margin: 10px 0 0;
	font-size: 20px;
}

.call-to-action .btn.btn-primary.white:hover {
	background: #000;
}

.call-to-action .btn.btn-primary.solid {
	margin-left: 15px;
}

/* Career */

.accordion {
	.card-header a {
		position: relative;
		padding-left: 30px;
		cursor: pointer;

		&::before,
		&.collapsed::before {
			font-family: FontAwesome;
			position: absolute;
			z-index: 0;
			font-size: 16px;
			left: 10px;
			padding: 0 5px;
			text-align: center;
		}
		&::before{
			content: "\f056";
		}
		&.collapsed::before {
			content: "\f055";
		}
	}
}

/* Testimonial */

.ts-testimonial-content {
	position: relative;
	padding: 30px 30px 15px 30px;
	border-width: 1px;
	border-style: solid;
	border-color: #e7e7e7;
	margin-bottom: 40px;
}


.ts-testimonial-content:after {
	content: "";
	position: absolute;
	bottom: -40px;
	left: 45px;
	border-style: solid;
	border-width: 40px 0px 0 30px;
	border-color: #e7e7e7 transparent;
	display: block;
	width: 0;
	z-index: 1;
}


.ts-testimonial-content img {
	float: left;
	width: 80px;
	height: 80px;
	border-radius: 100%;
}

.ts-testimonial-text {
	padding-left: 110px;
}

.ts-testimonial-author {
	position: absolute;
	bottom: -60px;
	left: 90px;
}

.ts-testimonial-author h3.name {
	font-size: 14px;
	margin: 10px 0 0;
}

.ts-testimonial-author h3 span {
	display: block;
	font-size: 13px;
	margin-top: -10px;
	text-transform: capitalize;
	color: #555;
}


/* Faq Page */

.faq-box {
	margin-bottom: 45px;
}

.faq-box h4 {
	margin-bottom: 25px;
}

/* 404 page */

.error-page .error-code {
	display: block;
	font-size: 150px;
	line-height: 150px;
	color: #333;
	margin-bottom: 20px;
	text-shadow: 5px 5px 1px rgba(0, 0, 0, .1);
}

.error-page .error-body .btn {
	margin-top: 30px;
	font-weight: 700;
}


/* Blog
================================================== */

/* Blog Listing */

.post {
	margin: 0 0 40px;
	padding: 0 0 40px;
	border-bottom: 1px solid #F5F5F5;
}

.post.post-last {
	border-bottom: 0;
}

.post-image-wrapper {
	margin-bottom: 20px;
	position: relative;
}

.blog-date {
	position: absolute;
	top: 50px;
	left: 0;
	color: #fff;
	border-radius: 0 25px 25px 0;
	padding: 5px 15px;
	font-weight: 700;
}

.blog-date a {
	color: #fff;
}

.post-footer .btn.btn-primary {
	font-size: 12px;
	padding: 8px 20px;
	margin-top: 15px;
}


/* Blog Single */



.post-meta {
	color: #959595;
	font-weight: 400;
	font-size: 13px;
}

.post-meta span {
	padding-right: 10px;
}

.post-meta a {
	color: #959595
}

.post-meta-author a,
.post-meta-cats a {
	color: #323232;
}

.entry-content {
	padding: 20px 0 10px;
}

/* Blog author */

.about-author {
	background: #f2f2f2;
	padding: 15px 20px;
	margin: 20px 0;
}

.author-img img {
	width: 120px;
	height: 120px;
	margin-right: 30px;
	border-radius: 100%;
	margin-top: 20px;
}

.author-url span a {
	color: #959595;
}

.author-info h3 {
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: normal;
}

.author-info h3 span {
	font-size: 14px;
	color: #959595;
	border-left: 1px solid #AFAFAF;
	padding-left: 8px;
	margin-left: 5px;
}

/* Blog post comments */

.comments-counter {
	font-size: 18px;
}

.comments-counter a {
	color: #323232;
}

.comments-list {
	list-style: none;
	margin: 0;
	padding: 20px 0;
}

.comments-list .comment {
	border-bottom: 1px solid #cecece;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.comments-list .comment.last {
	border-bottom: 0;
}

.comments-list img.comment-avatar {
	width: 84px;
	height: 84px;
	margin-right: 30px;
	border-radius: 100%;
}

.comments-list .comment-body {
	margin-left: 120px;
}

.comments-list .comment-author {
	margin-bottom: 0;
}

.comments-list .comment-date {
	font-style: italic;
	color: #959595;
	margin-bottom: 20px;
}

.comments-list .comment-reply {
	text-transform: uppercase;
	color: #323232;
}

.comments-reply {
	list-style: none;
	margin: 0 0 0 70px;
}

.comments-form .btn.btn-primary {
	padding: 10px 20px;
}

.comments-form label {
	font-weight: normal;
	color: #959595;
}

/* Sidebar */

.sidebar h3.widget-title {
	font-size: 18px;
	line-height: normal;
	margin-top: 0;
}

.sidebar .widget {
	margin-bottom: 40px;
}

.sidebar-right {
	padding-left: 20px;
}

/* Blog search */

.sidebar #search .input-group-btn .btn.btn-primary {
	padding: 11px 12px;
}


/*-- Blog tab widget --*/

.widget-tab .nav-tabs {
	margin: 0;
	text-align: center;
	border-bottom: 1px solid #e7e7e7;
}

.widget-tab .nav-tabs>li:first-child {
	margin-left: 10px;
}

.widget-tab .nav-tabs>li>a {
	background: #f2f2f2;
	color: #323232;
	font-size: 15px;
	font-weight: 700;
	text-transform: capitalize;
	border-radius: 3px 3px 0 0;
	margin-right: -1px;
	border: 1px solid #e7e7e7;
	border-bottom: 0;
}

.widget-tab .nav-tabs>li.active>a {
	background: #fff;
	color: #323232;
	border: 1px solid rgba(0, 0, 0, .1);
	border-bottom: 0;
}

.widget-tab .tab-content {
	border-top: 0;
	padding: 10px 0 0;
}

.widget-tab .posts-thumb img {
	display: block;
	width: 85px;
	height: 65px;
	margin-right: 20px;
}

.widget-tab .posts-avator img {
	display: block;
	width: 62px;
	height: 62px;
	margin-right: 20px;
	border-radius: 100%;
}

.widget-tab h4.entry-title {
	font-size: 11px;
	line-height: 15px;
	margin-bottom: 5px;
}

.widget-tab h4.entry-title a {
	color: #323232;
}

.widget-tab .post-meta span {
	padding-right: 5px;
}

.widget-tab ul.posts-list li {
	padding-bottom: 10px;
}


/*-- Blog category widget --*/

.widget-categories ul.category-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.widget-categories ul.category-list li {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #dedede;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.widget-categories ul.category-list li:hover {
	padding-left: 10px;
}

.widget-categories ul.category-list li a {
	color: #323232;
}

.widget-categories ul.category-list li:last-child {
	border-bottom: 0;
}

/*-- Blog tags widget --*/

.widget-tags ul>li {
	float: left;
	margin: 6px;
}

.widget-tags ul>li a {
	background: #f2f2f2;
	color: #6A6A6A;
	display: block;
	font-size: 14px;
	padding: 5px 15px;
	border-radius: 25px;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.widget-tags ul>li a:hover {
	color: #fff;
}

/* Landing page
================================================== */


/* Header */

.landing-header.header-bgnone {
	background: rgba(0, 0, 0, .8);
}


.hero-section {
	height: 100%;
	background-image: url(../images/landing/main-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: top center;
	padding-bottom: 0;
	padding-top: 200px;
	overflow: hidden;
}

.hero-section .container {
	height: 100%;
}

.hero-content {
	position: relative;
	display: table;
	height: 100%;
	width: 100%;
}

.hero-text {
	display: table-cell;
	vertical-align: bottom;
	color: #fff;
	padding-right: 50%;
	width: 100%;
	padding-bottom: 10%;

}

.hero-title {
	font-size: 40px;
	line-height: 50px;
	color: #fff;
}

.hero-description {
	margin-bottom: 30px;
	font-size: 18px;
	line-height: 30px;
	color: #fff;
}

.hero-images {
	display: block;
	position: absolute;
	bottom: 80px;
	right: 0;
	width: 50%;
	max-height: 100%;
}

.hero-images img {
	display: block;
	position: relative;
	max-height: 100%;
	width: auto;
}

.hero-buttons a.btn {
	color: #fff;
	padding: 15px 50px;
}

.hero-buttons a.btn.solid {
	margin-left: 10px;
}

.hero-buttons a i {
	margin-left: 5px;
}


/* Facts */

.landing_facts {
	color: #fff;
	padding: 50px 0;
}

.landing_facts .facts i {
	font-size: 36px;
}

.landing_facts .facts-num span {
	font-size: 56px;
}



/* Video and video background */

.video-embed {
	position: relative;
	padding-top: 56.25%;
	/* 16:9 */
	height: 0;
	background-color: #000000;
	-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.video-embed iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-background-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}

iframe {
	border: 0;
}

@media (min-width: 768px) {
	.video-section {
		padding: 100px 0;
	}
}

@media (min-width: 992px) {
	.video-section {
		padding: 120px 0;
	}
}

/* Screenshot image */

.app-gallery .grid {
	display: block;
	margin: 0 15px 20px;
	padding: 5px;
}

.app-gallery figure.effect-oscar a {
	margin: 125px 5px 0;
}

.app-gallery figure.effect-oscar figcaption::before {
	border: 0;
}


/* Subscribe Form */

.newsletter-form {
	position: relative;
}

.newsletter-form button {
	position: absolute;
	position: absolute;
	right: 0;
	top: 0;
	border-radius: 0 !important;
	color: #fff !important;
	padding: 14px 30px 12px !important;
}

.newsletter-form button:hover {
	color: #323232 !important;
}

/* Clients */

.landing_page_clients {
	padding-top: 30px;
}

/* Elements page
================================================== */

.testimonial.elements .testimonial-text,
.testimonial.elements .testimonial-content h3.name,
.testimonial.elements .testimonial-content h3 span {
	color: #323232;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.testimonial.elements .testimonial-slide.owl-theme .owl-controls .owl-page span {
	border: 1px solid #e7e7e7;
}



/* Style Swithcer
================================================== */

.style-switch-wrapper {
	position: fixed;
	top: 100px;
	right: -250px;
	width: 250px;
	height: auto;
	background-color: rgba(0, 0, 0, .75);
	border-bottom-right-radius: 4px;
	padding: 20px 20px;
	z-index: 1000;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.style-switch-wrapper h3 {
	color: #fff;
	margin-top: 0;
	margin-bottom: 20px;
}

.style-switch-wrapper.active {
	right: 0;
}

.style-switch-button {
	position: absolute;
	top: 0;
	right: 250px;
	width: 50px;
	height: 50px;
	background-color: rgba(0, 0, 0, .75);
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	z-index: 1000;
	cursor: pointer;
}

.style-switch-button i {
	color: #FFF;
	position: relative;
	line-height: 1;
	top: 15px;
	left: 15px;
	font-size: 20px;
}

.style-switch-wrapper button {
	width: 30px;
	height: 30px;
	border-radius: 100% !important;
	margin-bottom: 5px;
	color: #fff;
	display: inline-block;
	padding: 0 !important;
	margin-right: 2px;
	border: 0 !important;
}

a.close-styler {
	color: #fff !important;
	padding: 7px 20px !important;
	margin-right: 6px;
}

.style-switch-wrapper button:active,
.style-switch-wrapper button:focus {
	border: 2px solid #FFF;
}

.style-switch-wrapper #preset1 {
	background-color: #49a2e7;
}

.style-switch-wrapper #preset2 {
	background-color: #81c83c;
}

.style-switch-wrapper #preset3 {
	background-color: #ee3b24;
}

.style-switch-wrapper #preset4 {
	background-color: #ffc400;
}

.style-switch-wrapper #preset5 {
	background-color: #334F96;
}

.style-switch-wrapper #preset6 {
	background-color: #8C42BA;
}